import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Seo from "../SEO";
import Principal from "./Swiper/Principal";
import ClientsBox from "./Clients";
import Discounts from "./Discounts";
import Categories from "./Categories";
import Topics from "./Topics";
import Testimonial from "./Swiper/Testimonial";
import Partners from "./Partners";
// import Join from "../Popus/Join";


// import adv from "../../assets/img/banner-mes-experto.png";
// import advM from "../../assets/img/banner-mes-expertoM.png";

import { getTopics } from "../../actions/topics";
import { NavLink } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';

const LIMIT_TOPICS_HOME = 4;

export default function Home({ logged, pu, showPu }) {
  const [open, setOpen] = useState(pu);
  const dispatch = useDispatch();
  const titlePage = "Mundo Experto";
  const sections = {
    slider: true,
    accesos: true,
    descuento: false,
    categorias: true,
    temas: false,
    testimonios: false,
    socios: false,
  };
  // const categories = useSelector((state) => state.categories_topics);
  // const { data: dataCategoriesTopic = {} } = categories;
  // const { topics_category: categoriesTopic = [] } = dataCategoriesTopic;

  const topics = useSelector((state) => state.topics);
  const { data: dataTopics = {} } = topics;
  const { topics: infoTopics = [] } = dataTopics;

  useEffect(() => {
    // dispatch(getCategoriesTopics());
    sections.temas &&
      dispatch(
        getTopics({
          query: {
            limit: LIMIT_TOPICS_HOME,
          },
        })
      );
    showPu(false);
  }, [dispatch, sections.temas, showPu]);

  return (
    <>
      <Seo
        description="Conoce más del club de beneficios exclusivo de Mundo Experto de Easy, y aprovecha los múltiples beneficios y descuentos que tenemos pensado para los profesionales de la construcción"
        title={`${titlePage} - Easy`}
        home
        og={{
          description:
            "Conoce más del club de beneficios exclusivo de Mundo Experto de Easy, y aprovecha los múltiples beneficios y descuentos que tenemos pensado para los profesionales de la construcción",
        }}
      />

      <h1 className="oculto-xs">{titlePage}</h1>

      {sections.slider && <Principal />}

      <div className="container">
        {/* <div className="separador"></div> */}
        <NavLink to="/#">
          <picture>
            <source srcSet="/assets/img/banner-guia-experto-new.jpg" />
            <img
              src="/assets/img/banner-guia-experto-new.jpg"
              alt="Guía Experto"
              style={{
                width: "100%",
              }}
            />
          </picture>
        </NavLink>
        <div className="separador--big"></div>
      </div>
      {/* <div className="container">
        <div className="separador"></div>
        <a
          href="https://www.easy.cl/tienda/categoria/mes-del-experto"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source srcSet={advM} media="(max-width:767px)" />
            <source srcSet={adv} />
            <img
              src={adv}
              alt="Mes del Experto"
              style={{
                width: "100%",
              }}
            />
          </picture>
        </a>
        <div className="separador--big"></div>
      </div> */}

      {sections.accesos && <ClientsBox />}

      {sections.descuento && <Discounts />}

      {sections.categorias && <Categories />}

      {sections.temas && <Topics infoTopics={infoTopics} />}

      {sections.testimonios && <Testimonial />}

      {sections.socios && <Partners />}

      {/* {!logged && open && <Join setOpen={setOpen} open={open} />} */}
      {/* {open && <Adv setOpen={setOpen} open={open} />} */}
    </>
  );
}
