import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { parse, differenceInYears, isValid } from 'date-fns';

import { getCategoriesTopics } from '../../actions/topics';
import { registerUser, validateUserExist } from '../../actions/auth';

import Seo from '../SEO';
import Terms from '../Popus/Terms';
import Privacy from '../Popus/Privacy';
import { validate, format } from 'rut.js';
import {
    isValidPhone,
    isValidEmail,
    isValidDate,
    CodeResponse,
} from '../../utils/helpers';

import RutPopup from '../Popus/Rut';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/';

function validateLimit(value) {
    const numberPart = value.split('-')[0].replace(/\./g, '');
    if (parseInt(numberPart, 10) > 50000000) return false;
    return true;
}

export default function Register() {
    const dispatch = useDispatch();
    const { register, errors, getValues, handleSubmit, setValue } = useForm({
        mode: 'onBlur',
    });
    const [termsOpen, setTermsOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);

    // VALIDAR SI EXISTE RUT
    const [modalRut, showModalRut] = useState(false);
    const handleRut = (e) => {
        let rut = e.currentTarget;
        if (validate(rut.value)) {
            dispatch(
                validateUserExist({
                    dni: rut.value,
                })
            );
        }
    };
    const today = new Date();
    const onSubmitRegister = (data = {}) => {
        if (Object.keys(data).length > 0) {
            const {
                firstname = '',
                lastname = '',
                email = '',
                password = '',
                validate = 'email',
                category_topic: categories = [],
                dni = '',
                birthdate = '',
                specialty = '',
                purchaseFrequency = '',
                //phone = "",
                mobile = '',
            } = data;

            const dataAdditional = [];
            dataAdditional.push({
                type: 'dni',
                data: dni,
            });
            dataAdditional.push({
                type: 'datebirth',
                data: birthdate,
            });
            dataAdditional.push({
                type: 'specialty',
                data: specialty,
            });
            dataAdditional.push({
                type: 'mobile',
                data: mobile,
            });
            dataAdditional.push({
                type: 'purchaseFrequency',
                data: purchaseFrequency,
            });
/*
dataAdditional.push({
type: "phone",
data: phone,
});
*/
            if (Array.isArray(categories) && categories.length > 0) {
                dataAdditional.push({
                    type: 'categories',
                    data: categories.join(',') || '',
                });
            }
            dispatch(
                registerUser({
                    firstname,
                    lastname,
                    email,
                    password,
                    validate,
                    data: dataAdditional,
                })
            );
        }
    };

    useEffect(() => {
        dispatch(getCategoriesTopics());
    }, [dispatch]);

// const categories = useSelector((state) => state.categories_topics);
// const { data: dataCategoriesTopic = {}, isFetching = true } =
//   categories || {};
// const { topics_category: categoriesTopic = [] } = dataCategoriesTopic || {};

    const registerDataUser = useSelector((state) => state.register);
    const {
        data: dataRegister = {},
        err: errRegister = {},
        isFetching: isFetchingRegister = true,
        validateUser = false,
    } = registerDataUser || {};
    const { code: codeErrRegister = '' } = errRegister || {};
    const {
        isFetching: isFetchingValidate = true,
        data: dataValidateUser = false,
    } = validateUser || {};

    useEffect(() => {
        if (dataValidateUser) {
            showModalRut(true);
            setValue('dni', '', { shouldValidate: true, shouldDirty: true });
        }
    }, [dataValidateUser, setValue]);

    return (
        <>
            <Seo
                title='Inscripción'
                description='Inscríbete al club de beneficios de Mundo Experto'
                url='/inscripcion'
            />
            <section className='registro'>
                <div className='registro__bg'>
                    <img src='assets/img/registro-bg.jpg' alt='Inscripción' />
                </div>
                <div className='registro__container container'>
                    <div className='registro__form'>
                        <form
                            className='form registro__form__content'
                            onSubmit={handleSubmit(onSubmitRegister)}
                        >
                            <h2 className='color--primario section__title h1 text-upper text-wide'>
                                Inscripción
                            </h2>
                            <p className='color--primario section__subtitle'>
                                Escribe tus datos de seguridad para entrar a tu
                                sesión.
                            </p>
                            <div className='separador--big'></div>
                            <fieldset className='form__fieldset'>
                                <div className='row'>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.firstname && (
                                                <span className='color--error'>
                                                    {errors.firstname.message}
                                                </span>
                                            )}
                                            <input
                                                className='text'
                                                id='firstname'
                                                name='firstname'
                                                placeholder='Juan'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                    minLength: {
                                                        value: 2,
                                                        message:
                                                            'Debe tener al menos 2 caracteres',
                                                    },
                                                })}
                                            />
                                            <label htmlFor='firstname'>
                                                Nombre
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.lastname && (
                                                <span className='color--error'>
                                                    {errors.lastname.message}
                                                </span>
                                            )}
                                            <input
                                                className='text'
                                                id='lastname'
                                                name='lastname'
                                                placeholder='Sepúlveda'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                    minLength: {
                                                        value: 2,
                                                        message:
                                                            'Debe tener al menos 2 caracteres',
                                                    },
                                                })}
                                            />
                                            <label htmlFor='lastname'>
                                                Apellidos
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.dni &&
                                                !isFetchingValidate && (
                                                    <span className='color--error'>
                                                        {errors.dni.message}
                                                    </span>
                                                )}
                                            {isFetchingValidate && (
                                                <span className='color--terciario'>
                                                    Validando{' '}
                                                    <FontAwesomeIcon
                                                        icon={faSpinner}
                                                        className='fa-pulse'
                                                    />
                                                </span>
                                            )}
                                            <input
                                                className='text'
                                                id='dni'
                                                name='dni'
                                                placeholder='11.111.111-1'
                                                maxLength='13'
                                                onKeyUp={(e) =>
                                                    (e.target.value = format(
                                                        e.target.value
                                                    ))
                                                }
                                                onBlur={handleRut}
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                    maxLength: {
                                                        value: 12,
                                                        message:
                                                            'El RUT es inválido',
                                                    },
                                                    validate: {
                                                        validateDNI: (
                                                            value
                                                        ) => {
                                                            const limitCheck =
                                                                validateLimit(
                                                                    value
                                                                );

                                                            if (!limitCheck) {
                                                                return 'Servicio disponible solo para personas naturales';
                                                            }

                                                            const originalCheck =
                                                                validate(value);

                                                            if (!originalCheck)
                                                                return 'El RUT es inválido';

                                                            return true;
                                                        },
                                                    },
                                                })}
                                            />
                                            <label htmlFor='dni'>RUT</label>
                                        </div>
                                        {modalRut && (
                                            <RutPopup
                                                open={modalRut}
                                                setOpen={showModalRut}
                                            />
                                        )}
                                    </div>

                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.specialty && (
                                                <span className='alerta alerta--error alerta--xsmall'>
                                                    {errors.specialty.message}
                                                </span>
                                            )}
                                            <div className='icono'>
                                                <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                />
                                            </div>
                                            <select
                                                name='specialty'
                                                id='specialty'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                })}
                                            >
                                                <option value='' hidden>
                                                    Seleccionar
                                                </option>
                                                <option value='pintor'>
                                                    Pintor
                                                </option>
                                                <option value='constructor'>
                                                    Constructor
                                                </option>
                                                <option value='ingeniero'>
                                                    Ingeniero
                                                </option>
                                                <option value='arquitecto'>
                                                    Arquitecto
                                                </option>
                                                <option value='paisajista'>
                                                    Paisajista
                                                </option>
                                                <option value='ceramista'>
                                                    Ceramista
                                                </option>
                                                <option value='jardinero'>
                                                    Jardinero
                                                </option>
                                                <option value='alabanil'>
                                                    Albañil
                                                </option>
                                                <option value='carpintero'>
                                                    Carpintero
                                                </option>
                                                <option value='electricista'>
                                                    Electricista
                                                </option>
                                                <option value='gasfiter'>
                                                    Gasfíter
                                                </option>
                                                <option value='otro'>
                                                    Otro
                                                </option>
                                            </select>
                                            <label htmlFor='specialty'>
                                                Especialidad
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.purchaseFrequency && (
                                                <span className='alerta alerta--error alerta--xsmall'>
                                                    {errors.purchaseFrequency.message}
                                                </span>
                                            )}
                                            <div className='icono'>
                                                <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                />
                                            </div>
                                            <select
                                                name='purchaseFrequency'
                                                id='purchaseFrequency'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                })}
                                            >
                                                <option value='' hidden>
                                                    Seleccionar
                                                </option>
                                                <option value='semanal'>
                                                    Semanal
                                                </option>
                                                <option value='quincenal'>
                                                    Quincenal
                                                </option>
                                                <option value='mensual'>
                                                    Mensual
                                                </option>
                                                <option value='cada3Meses'>
                                                    Cada 3 meses
                                                </option>
                                                <option value='cada6MesesOMas'>
                                                    Cada 6 meses o más
                                                </option>
                                            </select>
                                            <label htmlFor='purchaseFrequency'>
                                                Frecuencia de compra
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.birthdate && (
                                                <span className='color--error'>
                                                    {errors.birthdate.message}
                                                </span>
                                            )}
                                            <InputMask
                                                className='text'
                                                id='birthdate'
                                                name='birthdate'
                                                mask='99/99/9999'
                                                placeholder='14/05/1980'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                    validate: {
                                                        validateDate: (value) =>
                                                            isValidDate(
                                                                value
                                                            ) ||
                                                            'La fecha es inválida',
                                                        validateBirthdate: (
                                                            value
                                                        ) => {
                                                            const birthdate =
                                                                parse(
                                                                    value,
                                                                    'dd/MM/yyyy',
                                                                    new Date()
                                                                );

                                                            if (
                                                                !isValid(
                                                                    birthdate
                                                                )
                                                            ) {
                                                                return 'La fecha es inválida';
                                                            }

                                                            const age =
                                                                differenceInYears(
                                                                    today,
                                                                    birthdate
                                                                );

                                                            if (
                                                                age < 18 ||
                                                                age >= 99
                                                            ) {
                                                                return 'La edad debe estar entre 18 y 98 años';
                                                            }

                                                            return true; // La validación es exitosa
                                                        },
                                                    },
                                                })}
                                            />
                                            <label htmlFor='birthdate'>
                                                Fecha nacimiento
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.mobile && (
                                                <span className='color--error'>
                                                    {errors.mobile.message}
                                                </span>
                                            )}
                                            <InputMask
                                                className='text'
                                                id='mobile'
                                                name='mobile'
                                                placeholder='+56 ...'
                                                mask='+56999999999'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                    validate: {
                                                        validateMobile: (
                                                            value
                                                        ) =>
                                                            isValidPhone(
                                                                value
                                                            ) ||
                                                            'El teléfono es inválido',
                                                    },
                                                })}
                                            />
                                            <label htmlFor='mobile'>
                                                Teléfono
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.email && (
                                                <span className='color--error'>
                                                    {errors.email.message}
                                                </span>
                                            )}
                                            <input
                                                className='text'
                                                id='email'
                                                type='text'
                                                name='email'
                                                placeholder='nombre@tudominio.com'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                    validate: {
                                                        validateEmail: (
                                                            value
                                                        ) =>
                                                            isValidEmail(
                                                                value
                                                            ) ||
                                                            'El email es inválido',
                                                    },
                                                })}
                                            />
                                            <label htmlFor='email'>Email</label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'></div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.password && (
                                                <span className='color--error'>
                                                    {errors.password.message}
                                                </span>
                                            )}
                                            <input
                                                className='text'
                                                id='password'
                                                type='password'
                                                name='password'
                                                placeholder='•••••••••'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                })}
                                            />
                                            <label htmlFor='password'>
                                                Contraseña
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.repeat_password && (
                                                <span className='color--error'>
                                                    {
                                                        errors.repeat_password
                                                            .message
                                                    }
                                                </span>
                                            )}
                                            <input
                                                className='text'
                                                id='repeat_password'
                                                type='password'
                                                name='repeat_password'
                                                placeholder='•••••••••'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                    validate: {
                                                        matchesPreviousPassword:
                                                            (value) => {
                                                                const {
                                                                    password,
                                                                } = getValues();
                                                                return (
                                                                    password ===
                                                                        value ||
                                                                    'Las contraseñas ingresadas no coinciden'
                                                                );
                                                            },
                                                    },
                                                })}
                                            />
                                            <label htmlFor='repeat_password'>
                                                Repetir Contraseña
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div className='col-xs-12'>
                                        <div className='form__checkbox'>
                                            <input
                                                id='terms'
                                                type='checkbox'
                                                name='terms'
                                                value='1'
                                                onClick={() =>
                                                    setTermsOpen(!termsOpen)
                                                }
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                })}
                                            />
                                            <div className='form__checkbox__item'></div>
                                            <label htmlFor='terms'>
                                                Acepto los{' '}
                                                <span className='link-externo'>
                                                    términos y condiciones
                                                </span>
                                            </label>
                                        </div>
                                        {termsOpen && (
                                            <Terms
                                                open={termsOpen}
                                                setOpen={setTermsOpen}
                                            />
                                        )}
                                        {errors.terms && (
                                            <span className='color--error'>
                                                {errors.terms.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-xs-12'>
                                        <div className='separador'></div>
                                        <div className='form__checkbox'>
                                            <input
                                                id='politicas'
                                                type='checkbox'
                                                name='politicas'
                                                value='1'
                                                onClick={() => {
                                                    setPrivacyOpen(
                                                        !privacyOpen
                                                    );
                                                }}
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                })}
                                            />
                                            <div className='form__checkbox__item'></div>
                                            <label htmlFor='politicas'>
                                                Acepto las{' '}
                                                <span className='link-externo'>
                                                    políticas de privacidad
                                                </span>
                                            </label>
                                        </div>
                                        {privacyOpen && (
                                            <Privacy
                                                open={privacyOpen}
                                                setOpen={setPrivacyOpen}
                                            />
                                        )}
                                        {errors.terms && (
                                            <span className='color--error'>
                                                {errors.terms.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-xs-12'>
                                        <div className='separador--big'></div>
                                        {isFetchingRegister && (
                                            <div className='preloader'>
                                                <div className='preloader__child'></div>
                                            </div>
                                        )}
                                        {codeErrRegister && (
                                            <div>
                                                {CodeResponse(codeErrRegister)}
                                            </div>
                                        )}
                                        {Object.keys(dataRegister).length >
                                            0 && (
                                            <Redirect to='/inscripcion/exito' />
                                        )}
                                        {((!isFetchingRegister &&
                                            Object.keys(dataRegister).length <
                                                1) ||
                                            codeErrRegister) && (
                                            <button
                                                type='submit'
                                                className='btn btn--small btn--terciario btn--invertido btn--redondo'
                                            >
                                                Registrarme
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className='separador'></div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
